import axios from 'axios'
import { isEmpty, toUpper } from 'lodash'
import qsUtil from '../../../common/util/queryStringUtil'

import apiConfig from '../../../config/apiConfig'

async function getSubscriptionData(params) {
  const mappedParams = {
    ...params,
    selected_filters: [],
    event_types: [],
    notification_types: [
      ...new Set(
        params.event_types.flatMap((value) => {
          return value.group.replaceAll(' ', '_')
        }),
      ),
    ],
    event_codes: params.event_types.flatMap((value) => {
      return value.value || value.id
    }),
    origin_ids: params.origin_ids
      ? params.origin_ids.map((value) => value.value || value.id)
      : [],
    destination_ids: params.destination_ids
      ? params.destination_ids.map((value) => value.value || value.id)
      : [],
    subcategories: params.categories
      ? params.categories.flatMap((value) => value.value || value.id)
      : [],
    alert_statuses: params.alert_statuses.flatMap(
      (value) => value.value || value.id,
    ),
  }
  const { data } = await axios.get(`${apiConfig.api.edge}/subscriptions`, {
    params: qsUtil.createParams(mappedParams),
  })
  return data
}

async function getSubscriptionConfig() {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/subscription_config_details`,
  )
  return data
}

async function getLocations() {
  const { data } = await axios.get(`${apiConfig.api.edge}/dcs`)
  return data
}

async function createSubscription(subscriptionData) {
  const requestBody = createSubscriptionRequestBody(subscriptionData)
  return axios.post(`${apiConfig.api.edge}/subscriptions`, requestBody)
}

async function deleteSubscription(ids) {
  return axios.delete(`${apiConfig.api.edge}/subscriptions?ids=${ids}`)
}

function createSubscriptionRequestBody(subscriptionData) {
  const requestBody = {
    notification_type: null,
    event: {
      code: null,
      description: null,
    },
    scacs: null,
    alert_statuses: null,
    subcategories: null,
    origins: null,
    destinations: null,
    receivers: {
      email_addresses: null,
    },
  }

  const notificationType = subscriptionData.notification_type
  if (!isEmpty(notificationType)) {
    requestBody.notification_type = notificationType
  }

  const eventType = subscriptionData.event_types
  if (eventType) {
    requestBody.event.code = eventType.id
    requestBody.event.description = eventType.label
  }
  const scacs = subscriptionData.scacs
  if (!isEmpty(scacs)) {
    requestBody.scacs = scacs.map(toUpper)
  }

  const carrierScacs = subscriptionData.carrier_scacs
  if (!isEmpty(carrierScacs)) {
    requestBody.scacs = carrierScacs.map((scac) => scac.value)
  }

  const alertStatuses = subscriptionData.alert_statuses
  if (!isEmpty(alertStatuses)) {
    requestBody.alert_statuses = alertStatuses.map(
      (alertStatus) => alertStatus.value,
    )
  }

  const subcategories = subscriptionData.subcategories
  if (!isEmpty(subcategories)) {
    requestBody.subcategories = subcategories.map(
      (subcategory) => subcategory.value,
    )
  }

  const origins = subscriptionData.origins
  if (!isEmpty(origins)) {
    requestBody.origins = origins
  }

  const destinations = subscriptionData.destinations
  if (!isEmpty(destinations)) {
    requestBody.destinations = destinations
  }

  const emails = subscriptionData.emails
  if (!isEmpty(emails)) {
    requestBody.receivers.email_addresses = emails
  }
  return requestBody
}

export default {
  getSubscriptionData,
  getSubscriptionConfig,
  createSubscription,
  deleteSubscription,
  getLocations,
}
