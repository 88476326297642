export const DEFAULT_SUBSCRIPTION_VALUES = {
  notification_type: null,
  event_types: null,
  alert_statuses: [],
  scacs: [],
  subcategories: [],
  origins: [],
  destinations: [],
  creator_emails: [],
  carrier_scacs: [],
}
