export const DEFAULT_SUBSCRIPTION_SEARCH_FILTERS = {
  notification_types: [],
  scacs: [],
  origin_ids: [],
  destination_ids: [],
  event_types: [],
  alert_statuses: [],
  event_code: [],
  categories: [],
  creator_emails: [],
}

export const FILTER_LABELS = {
  notification_types: 'Notification Types',
  scacs: 'SCACs',
  event_code: 'Event Code',
  event_types: 'Event Types',
  origin_ids: 'Origin',
  destination_ids: 'Destination',
  alert_statuses: 'Alert Status',
  categories: 'Load Category',
  creator_emails: 'Creator Emails',
}
