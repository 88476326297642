import { useCallback, useEffect, useState } from 'react'
import {
  Card,
  Grid,
  Heading,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import SubscriptionModal from './SubscriptionModal'
import { useAuth } from '@praxis/component-auth'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'
import subscriptionService from './services/subscriptionService'
import NotificationsTable from './NotificationsTable'
import { isEmpty, chain, pull } from 'lodash'
import { ProgressOverlay } from '@dlm/common'

import '../../stylesheets/customStyles.scss'
import SubscriptionSearchFilterDrawer from './SubscriptionSearchFilterDrawer'
import { DEFAULT_SUBSCRIPTION_SEARCH_FILTERS } from './constants/searchFilterConstants'
import SearchFilterChips from './SearchFilterChips'

const Notifications = () => {
  const auth = useAuth()
  const { session } = auth
  const user = session?.userInfo
  const accessToken = session?.accessToken
  // Configure any axios interceptors here
  // Usually we set interceptors globally, but this needs to be inside the component to work with federation
  axios.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = apiConfig.api.key
    // Usually populated by praxis by default, but doesn't work if accessed from parent mfe app
    config.headers['Authorization'] =
      accessToken && accessToken.includes('Bearer')
        ? accessToken
        : `Bearer ${accessToken}`
    return config
  })

  const makeToast = ToastProvider.useToaster()
  const [subscriptionConfig, setSubscriptionConfig] = useState([])
  const [subscriptionData, setSubscriptionData] = useState(null)

  const [inProgress, setInProgress] = useState(false)

  const [selectedRows, setSelectedRows] = useState([])
  const [filtersVisible, setFiltersVisible] = useState(false)

  const [subscriptionSearchFilters, setSubscriptionSearchFilters] = useState({
    page: 1,
    per_page: 20,
    selected_filters: [],
    ...DEFAULT_SUBSCRIPTION_SEARCH_FILTERS,
  })

  const [subscriptionCount, setSubscriptionCount] = useState(0)

  const [locationsNode, setLocationsNode] = useState([])

  const onPaginationChange = (pageNum, pageSize) => {
    setSubscriptionSearchFilters((loadSearchFilters) => ({
      ...loadSearchFilters,
      page: pageNum,
      per_page: pageSize,
    }))
  }
  const onModalClose = () => {
    getSubscriptions()
    setSelectedRows([])
  }

  const onRowSelect = (e) => {
    setSelectedRows(e)
  }

  const onSearchFilterDelete = (filter) => {
    setSubscriptionSearchFilters({
      ...subscriptionSearchFilters,
      selected_filters: pull(
        subscriptionSearchFilters.selected_filters,
        filter,
      ),
      [filter]: DEFAULT_SUBSCRIPTION_SEARCH_FILTERS[filter],
    })
  }

  const onSearchFilterReset = () => {
    setSubscriptionSearchFilters({
      page: 1,
      per_page: subscriptionSearchFilters.per_page,
      selected_filters: [],
      ...DEFAULT_SUBSCRIPTION_SEARCH_FILTERS,
    })
  }

  const getSubscriptions = useCallback(() => {
    setInProgress(true)
    subscriptionService
      .getSubscriptionData(subscriptionSearchFilters)
      .then((resp) => {
        setSubscriptionCount(resp.row_count)
        setSubscriptionData(resp.subscriptions)
      })
      .catch((error) => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: `Error fetching subscription details ${error.message}`,
        })
      })
      .finally(() => {
        // TODO - Remove timeout after https://jira.target.com/browse/EUI-1740 is implemented
        setTimeout(() => setInProgress(false), 500)
      })
  }, [subscriptionSearchFilters, makeToast])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchFilterChange = (newFilters) => {
    const updatedSelectedFilters = chain(newFilters)
      .pick(Object.keys(DEFAULT_SUBSCRIPTION_SEARCH_FILTERS))
      .omitBy(isEmpty)
      .keys()
      .value()

    // Need to re-instate pagination and sort properties as filter drawer strips them
    setSubscriptionSearchFilters({
      ...newFilters,
      page: 1,
      per_page: subscriptionSearchFilters.per_page,
      selected_filters: updatedSelectedFilters,
    })
  }

  useEffect(() => {
    getSubscriptions()
  }, [getSubscriptions])

  useEffect(() => {
    subscriptionService
      .getSubscriptionConfig(user)
      .then((resp) => {
        setSubscriptionConfig(resp)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching subscription config',
        })
      })
  }, [user, makeToast])

  useEffect(() => {
    subscriptionService
      .getLocations()
      .then((resp) => {
        const locationNodeData = resp.map((node) => ({
          label: `${node.node_id} - ${node.display_name}`,
          value: node.node_id,
          id: node.node_id,
          group: node.sub_type_code,
        }))
        setLocationsNode(locationNodeData)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching location data',
        })
      })
  }, [makeToast])

  return (
    <>
      {subscriptionData !== null &&
        isEmpty(subscriptionData) &&
        isEmpty(subscriptionSearchFilters.selected_filters) && (
          <Card className="topPage-container" data-testid="notificationsPage">
            <Grid.Container
              justify="center"
              align="center"
              direction="column"
              className="fullHeight-container"
            >
              <Grid.Item className="hc-pb-none">
                <Heading size={1} className="">
                  Welcome to Notifications!
                </Heading>
              </Grid.Item>
              <Grid.Item />
              <Grid.Item className="hc-pt-none hc-pb-xl">
                <Heading size={5}>
                  Get started by configuring your first notification.
                </Heading>
              </Grid.Item>
              <Grid.Item>
                <SubscriptionModal
                  subscriptionConfig={subscriptionConfig}
                  onModalClose={onModalClose}
                />
              </Grid.Item>
            </Grid.Container>
          </Card>
        )}
      {(!isEmpty(subscriptionData) ||
        !isEmpty(subscriptionSearchFilters.selected_filters)) && (
        <>
          <ProgressOverlay inProgress={inProgress} />
          <SubscriptionSearchFilterDrawer
            isVisible={filtersVisible}
            onRequestClose={() => {
              setFiltersVisible(false)
            }}
            onChange={onSearchFilterChange}
            eventConfig={subscriptionConfig}
            locationsOptions={locationsNode}
            givenValues={subscriptionSearchFilters}
          />
          <NotificationsTable
            subscriptionData={subscriptionData}
            subscriptionConfig={subscriptionConfig}
            pageSize={subscriptionSearchFilters.per_page}
            pageNum={subscriptionSearchFilters.page}
            subscriptionCount={subscriptionCount}
            onPaginationChange={onPaginationChange}
            onModalClose={onModalClose}
            onRowSelect={onRowSelect}
            selectedRows={selectedRows}
            setFiltersVisible={setFiltersVisible}
            subHeader={
              <SearchFilterChips
                searchFilters={subscriptionSearchFilters}
                onDelete={onSearchFilterDelete}
                onReset={onSearchFilterReset}
              />
            }
          />
        </>
      )}
    </>
  )
}

export default Notifications
