import { useEffect, useState } from 'react'

import useUser from './useUser'
import businessPartnerService from '../../views/Notifications/services/businessPartnerService'

const useBusinessPartner = () => {
  const [businessPartnerDetails, setBusinessPartnerDetails] = useState([])

  const {
    access: { isCarrier },
    lanId,
  } = useUser()

  useEffect(() => {
    if (isCarrier) {
      businessPartnerService.getBusinessPartnerDetails(lanId).then((data) => {
        setBusinessPartnerDetails(data)
      })
    }
  }, [isCarrier, lanId])

  return businessPartnerDetails
}

export default useBusinessPartner
