import Notifications from '../views/Notifications/Notifications'

const routes = [
  {
    path: '/',
    component: <Notifications />,
  },
]

const sideNavItems = {
  notifications: {
    type: 'item',
    path: '/',
    label: 'Notifications',
  },
}

export default {
  routes,
  sideNavItems,
}
