import { NovaFilterDrawer } from '@dlm/common'

import useUser from '../../common/hooks/useUser'
import { originDestinationFilterValidationRule } from './util/validationUtil'
import { DEFAULT_SUBSCRIPTION_SEARCH_FILTERS } from './constants/searchFilterConstants'

const mapEventTypeOptions = (eventConfigs) => {
  const mapped = []

  eventConfigs.forEach((eventConfig) => {
    eventConfig.events?.map((event) =>
      mapped.push({
        label: event.description,
        value: event.code,
        id: event.code,
        group: event.type.replaceAll('_', ' '),
      }),
    )
  })
  return mapped
}

const mapSubcategories = (eventConfigs) => {
  const mapped = []

  eventConfigs.forEach((eventConfig) => {
    eventConfig.subcategories?.map((subcategory) =>
      mapped.push({
        label: subcategory.replaceAll('_', ' '),
        value: subcategory,
        id: subcategory,
        group: eventConfig.notification_type.replaceAll('_', ' '),
      }),
    )
  })
  return mapped
}

const mapAlertOptions = (eventConfigs) => {
  const collectedStatuses = []
  eventConfigs.forEach((eventConfig) => {
    eventConfig.events?.map((event) =>
      event.event_details?.alert_statuses?.map((alertStatus) =>
        collectedStatuses.push({
          label: alertStatus,
          value: alertStatus,
          id: alertStatus,
        }),
      ),
    )
  })
  return collectedStatuses
}

const SubscriptionSearchFilterDrawer = ({
  onChange,
  isVisible,
  onRequestClose,
  eventConfig,
  locationsOptions,
  givenValues,
}) => {
  const {
    access: { isCarrier },
  } = useUser()

  const eventOptions = mapEventTypeOptions(eventConfig)
  const alertOptions = mapAlertOptions(eventConfig)
  const formattedSubcategories = isCarrier ? [] : mapSubcategories(eventConfig)

  const handleChange = (values) => {
    // Needed to strip out object metadata added by hook form for tests to pass
    onChange(Object.assign({}, values))
    onRequestClose()
  }

  const categories = isCarrier
    ? []
    : [
        {
          type: 'autocomplete',
          name: 'categories',
          label: 'Categories',
          multiselect: true,
          ariaLabel: 'Categories List',
          placeholder: 'Comma separated list of categories',
          options: formattedSubcategories,
          xs: 12,
          autocompleteType: 'grouped',
        },
      ]
  const filterMeta = {
    1: [
      {
        type: 'multi-text',
        name: 'scacs',
        label: 'SCACs',
        ariaLabel: 'SCAC List',
        placeholder: 'Comma separated list of SCACs',
        xs: 12,
      },
    ],
    2: [
      {
        type: 'multi-text',
        name: 'creator_emails',
        label: 'Creator Emails',
        ariaLabel: 'Email List',
        placeholder: 'Comma separated list of creator emails',
        xs: 12,
      },
    ],
    3: [
      {
        type: 'autocomplete',
        name: 'origin_ids',
        label: 'Origin',
        multiselect: true,
        rules: originDestinationFilterValidationRule(),
        allowCustomInputValue: true,
        ariaLabel: 'Origin Location ID List',
        placeholder: 'Comma separated list of Origin IDs',
        options: locationsOptions,
        xs: 6,
        autocompleteType: 'grouped',
      },
      {
        type: 'autocomplete',
        name: 'destination_ids',
        multiselect: true,
        rules: originDestinationFilterValidationRule(),
        allowCustomInputValue: true,
        label: 'Destination',
        ariaLabel: 'Destination Location ID List',
        placeholder: 'Comma separated list of Destination IDs',
        options: locationsOptions,
        xs: 6,
        autocompleteType: 'grouped',
      },
    ],
    4: categories,
    5: [
      {
        type: 'autocomplete',
        name: 'event_types',
        multiselect: true,
        label: 'Event Type',
        placeholder: 'Comma separated list of event types',
        options: eventOptions,
        xs: 12,
        autocompleteType: 'grouped',
      },
    ],
    6: [
      {
        hintText: 'Alert status ony applicable if event type is Load Alert',
        type: 'autocomplete',
        name: 'alert_statuses',
        multiselect: true,
        label: 'Alert Statuses',
        options: alertOptions,
        xs: 12,
        enabledRule: [{ name: 'event_types', value: ['LA'] }],
      },
    ],
  }

  const filterActionsMeta = {
    1: [
      {
        type: 'reset',
        xs: 6,
      },
      {
        type: 'submit',
        xs: 6,
      },
    ],
  }

  return (
    <NovaFilterDrawer
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      headingText="Subscription Search Filters"
      location="right"
      filterMeta={filterMeta}
      filterActionsMeta={filterActionsMeta}
      defaultValues={DEFAULT_SUBSCRIPTION_SEARCH_FILTERS}
      onSubmit={handleChange}
      values={givenValues}
    />
  )
}

export default SubscriptionSearchFilterDrawer
