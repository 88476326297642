import { useState } from 'react'
import EnterpriseIcon, { TrashIcon } from '@enterprise-ui/icons'
import { Button, ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { ConfirmActionModal } from '@dlm/common'
import subscriptionService from '../services/subscriptionService'

const DeleteNotificationsButton = ({ selectedRows, onModalClose }) => {
  const makeToast = ToastProvider.useToaster()

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }

  const openConfirmModal = () => {
    setShowConfirmModal(true)
  }

  const deleteNotification = () => {
    subscriptionService
      .deleteSubscription(selectedRows)
      .then(() => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully deleted notifications.',
        })
        onModalClose()
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: `Error deleting notifications.`,
        })
      })
      .finally(() => {
        closeConfirmModal()
      })
  }

  return (
    <>
      <ConfirmActionModal
        headingText="Confirm delete of selected notifications"
        challengeText={`Are you sure you want to delete the selected notification${
          selectedRows?.length > 1 ? 's' : ''
        }?`}
        confirmButtonText="Confirm"
        confirmButtonAction={deleteNotification}
        cancelButtonText="Cancel"
        cancelButtonAction={closeConfirmModal}
        isVisible={showConfirmModal}
      />
      <Button
        id="deleteNotificationsButton"
        aria-label="Delete Notifications Button"
        type="destructive"
        size="normal"
        onClick={openConfirmModal}
        disabled={selectedRows?.length < 1}
      >
        <EnterpriseIcon icon={TrashIcon} className="hc-mr-dense" />
        Delete Selected Notifications
      </Button>
    </>
  )
}

export default DeleteNotificationsButton
