import axios from 'axios'
import apiConfig from '../../../config/apiConfig'

const getBusinessPartnerDetails = async (businessPartnerId) => {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/business_partner_scacs/${businessPartnerId}`,
  )
  return data
}

export default {
  getBusinessPartnerDetails,
}
