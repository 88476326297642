import merge from 'lodash/merge'

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  auth: {
    clientId: 'dlm_npe_im',
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/tgt/authorize/ad-pol/1',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    requiredPermissions: {
      user: ['APP-OAUTH2-MERCURY-USER-NPE'],
      admin: ['APP-OAUTH2-MERCURY-ADMIN-NPE'],
      carrier: ['APP-OAUTH2-MERCURY-CARRIER'],
      auction: ['APP-OAUTH2-MERCURY-AUCTION-NPE'],
    },
  },
  api: {
    edge: 'https://mercuryedge.dev.target.com/transportation_load_details/v1',
    externalUrl: 'https://stage-api.target.com/transportation_load_details/v1',
    key: '38145752664d9d8f2893aa37558ae1c13be3671a',
  },
}

const envConfigs = {
  dev: {},
  stg: {},
  prod: {
    auth: {
      // keys in envConfigs will overwrite keys from commonConfig
      authorizationUrl:
        'https://oauth.iam.target.com/auth/oauth/v2/tgt/authorize/ad-pol/1',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'dlm_prod_im',
      requiredPermissions: {
        user: ['APP-OAUTH2-MERCURY-USER'],
        admin: ['APP-OAUTH2-MERCURY-ADMIN'],
        carrier: ['APP-OAUTH2-MERCURY-CARRIER'],
        auction: ['APP-OAUTH2-MERCURY-AUCTION'],
      },
    },
    api: {
      edge: 'https://mercuryedge.prod.target.com/transportation_load_details/v1',
      externalUrl: 'https://api.target.com/transportation_load_details/v1',
      key: '56700114104fa91df4c4d240a82a348cbc11c762',
    },
  },
}

if (window.location.hostname.includes('partnersonline.com')) {
  commonConfig.api.edge = commonConfig.api.externalUrl
  envConfigs.prod.api.edge = envConfigs.prod.api.externalUrl
}

const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config)

export default apiConfig
