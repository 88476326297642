import { startCase, toLower, isEmpty } from 'lodash'

export const formatAutoCompleteData = (data) => {
  if (data) {
    return data.map((value) => {
      return { label: startCase(value), value: value, id: value }
    })
  }

  return [{ label: '', value: '', id: '' }]
}

export const formatEventTypes = (events) => {
  if (events) {
    return events.map((event) => {
      if (event && event.description && event.code) {
        return {
          label: startCase(event.description),
          value: event.code,
          id: event.code,
          otherInfo: {
            firstLine: startCase(event.description),
            secondLine: event.definition,
          },
        }
      } else {
        return [{ label: '', value: '', id: '', otherInfo: '' }]
      }
    })
  }
  return []
}

export const formatNotificationTypes = (eventConfig) => {
  if (!isEmpty(eventConfig)) {
    return eventConfig.map((config) => {
      return {
        label: startCase(toLower(config.notification_type)),
        value: config.notification_type,
      }
    })
  }

  return [{ label: '', value: '' }]
}
